import Typography from '@mui/material/Typography';
import markus_info from "../markus_personal_info.json";
import carsten_info from "../carsten_personal_info.json";
import { ComponentProps } from './ComponentProps';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Header from './Header';

export default function BooksPage() {
    let titleStyle = {
        margin: "10px 0"
    } 

    return (
        <Box style={{margin: "0 auto 100px auto", maxWidth: 860}}>
            <Header is_markus={true} />
            <Box id="pyramid-principle" style={{minHeight: "260px"}}>
                <img src="pyramid_principle.jpg" style={{
                    padding: ".25rem",
                    border: "1px solid #dee2e6",
                    borderRadius: ".25rem",
                    maxWidth: "150px",
                    height: "auto",
                    float:"right"
                }} alt="The Pyramid Principle"/>
                <Typography variant="h5" component="div" style={titleStyle}>The Pyramid Principle, Barbara Minto</Typography>
                Using this book, I wrote my Bachelor and Master theses in 24h and 3 days, achieving top grades.
                Although it gives concrete writing advice, I read it as a book about structure of thought.
                <br/>
                Barbara Minto raises three important points about business writing: 
                <ol>
                    <li>Start with the answer first</li>
                    <li>Group your arguments in bunches of 3</li>
                    <li>Logically order your supporting ideas (timewise, structural, degree)</li>
                </ol>
                In her explanation of point (2), she introduces a hierarchical structure of thought, which she calls pyramids.
                <img src="pyramids.png" style={{
                    padding: ".25rem",
                    border: "1px solid #dee2e6",
                    borderRadius: ".25rem",
                    margin: "20px 10% 20px 10%",
                    maxWidth: "80%"
                }} alt="Pyramids"/>
                Any piece of writing is split into three sections A, B and C and each section is split into three paragraphs a, b and c. 
                The leaf nodes of the pyramid contain concrete points and arguments.
                One recursively traverses the pyramid starting from the top, going downwards and from left to right, writing about the content of each node: 
                <br/>
                Title {'\u2192'} A {'\u2192'} a {'\u2192'} b {'\u2192'} c {'\u2192'} B ...
                <br/> 
                This practice ensures that the main points of the writing and their supporting arguments are well structured. 
                I found that I almost never need to rewrite sections anymore, because the writing has been well structured ahead of time.
                Preparing my writing in this way allowed me to accumulate results and arguments in very large trees with {'\u003E'}80 leaf nodes. 
                While collecting material, I often restructure the pyramid to align with the structure of the material that I found. 
                This explains the speed at which I can write: I have a clear structure of thought and I have collected all the material that I need. 
                Writing only consists of formulating sentences for every leaf node, as the hard structural work has already been done!
                <br/>
                <br/>
                The Pyramid Principle is written from the perspective of writing business reports at Mc Kinsey and therefore this super-concise law of threes applies. 
                For my Bachelor and Master theses, I softened this rule to fit the natural structure of the argument, allowing for more child nodes. 
                If there are five important supports to a claim, why not write all of them? 
                <br/>
                <br/>
                To represent pyramids I use an open source software called <a href="https://www.freeplane.org/oldwiki/index.php/Home">Freeplane</a>. 
                Although it's not very pretty, Freeplane supports all the features that I need: node editing, collapsing, summary nodes and notes. 
                I am already collecting and I fully expect to write my PhD thesis using this tool (although maybe not in 24h).    
                <br/>
                <br/>
                TLDR:
                <ul>
                    <li>Knowledge is hierarchical</li>
                    <li>Accumulating it in a pyramid provides hierarchical structure</li>
                    <li>Writing out the branches of the pyramid is easy</li>
                </ul>
            </Box>
            <hr style={{margin:"40px 0", color: "lightgray"}}/>
            <Box id="the-intelligent-investor" style={{minHeight: "260px"}}>
            <img src="the_intelligent_investor.jpeg" style={{
                    padding: ".25rem",
                    border: "1px solid #dee2e6",
                    borderRadius: ".25rem",
                    maxWidth: "150px",
                    height: "auto",
                    float:"right"
                }} alt="The Intelligent Investor"/>
                <Typography variant="h5" component="div"  style={titleStyle}>The Intelligent Investor, Benjamin Graham</Typography>
                
                This book explained value investing to me. Graham introduces the allegory of Mr. Market, who will show up every day with a different value proposal for stocks. 
                He behaves unpredictably and his opinions of the value of stock changes daily. 
                Often his valuations are reasonable, but he tends to overestimate stock value during boom phases (bull markets) and to underestimate values during declines (bear markets). 
                According to Graham, investors should not listen to Mr. Market when assessing the true value of stock. 
                Instead, one should rely on fundamentals and take Mr. Market's moody opinions as an opportunity to buy cheaply. 
                <br/>
                <br/>
                The tricky bit is to arrive at your own valuations. 
                Value investors look at the fundamentals of companies by assessing finance statements and comparing companies in their market segment according to four factors:
                Long term growth prospects, capital structure, quality of management and dividends. 
                I find this approach very enticing, as every stock traded company is required to release this kind of information publicly. 
            </Box>
        </Box>
        );
  }